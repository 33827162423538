import { storeToRefs } from 'pinia'
import {
  EVehicleType,
  IVehicle,
  NewVehicle,
  UsedVehicle,
  IVehicleAttribute,
  EConditionSlug
} from '~/types/vehicle'
import { useServiceStatusStore } from '~/stores/serviceStatusStore'
import { isNewVehicle } from '~/guards/vehicle'
import { ERouteConditions } from '~/types/routes'
import { useFiltersStore } from '~/stores/filtersStore'
import { IRetailer } from '~/types/retailer'
import { useVehiclesSearchStore } from '~/stores/vehicles/searchStore'
import { useCustomerTypeStore } from '~/stores/customerTypeStore'

export const useVehicle = (
  vehicle?: Ref<IVehicle | NewVehicle | UsedVehicle | null>
) => {
  const customerTypeStore = useCustomerTypeStore()
  const vehiclesSearchStore = useVehiclesSearchStore()
  const serviceStatusStore = useServiceStatusStore()

  const showP11d = computed<boolean>(
    () => customerTypeStore.isBusiness && !!vehicle?.value?.P11D && isNew.value
  )

  const isNew = computed<boolean>(() => {
    return vehicle?.value?.VehicleType === EVehicleType.New
  })

  const isNightMode = computed<boolean>(() => {
    return vehiclesSearchStore.isNightMode
  })

  const conditionSlug = computed<string>(() => {
    return isNew ? EConditionSlug.New : EConditionSlug.Used
  })

  const isAMG = computed<boolean>(
    () => vehicle?.value?.Brand?.Description === 'AMG'
  )

  const isMPV = computed<boolean>(() => vehicle?.value?.BodyStyle === 'MPV')

  const isSmart = computed<boolean>(() => {
    return vehicle?.value?.Brand?.Description?.toUpperCase() === 'SMART'
  })

  const isAdvertiseOnly = computed(() => {
    if (isNewVehicle(vehicle?.value))
      return vehicle!.value.VehicleUsageCode === '18'

    return false
  })

  const canBuy = computed<boolean>(() => {
    if (!vehicle?.value || !serviceStatusStore.agency) return false

    return (
      isNew.value &&
      !vehicle?.value.Secured &&
      !vehicle?.value.UnderOffer &&
      (vehicle?.value?.IsAvailableOnline ?? true) &&
      !isAdvertiseOnly.value
    )
  })

  /**
   * Returns delivery time for a vehicle
   */
  const deliveryTime = computed<IVehicleAttribute | undefined>(() => {
    if (isAdvertiseOnly.value) {
      return {
        text: 'Stock Coming Soon'
      }
    }
    if (vehicle?.value?.IsPipelineStock) {
      return {
        text: ''
      }
    }
    if (vehicle?.value?.IsDisplayStock) {
      return {
        text: 'Available to view at this showroom'
      }
    }
    if (vehicle?.value?.PortArrivalDate) {
      return {
        text: ''
      }
    }

    return undefined
  })

  // TODO: Move to `useStockLevel`
  const hasStockLevel = computed<boolean>(() => {
    if (!vehicle?.value || !isNewVehicle(vehicle.value)) return false

    return (
      vehicle?.value?.StockLevelIndicator === 'G' ||
      vehicle?.value?.StockLevelIndicator === 'Y' ||
      vehicle?.value?.StockLevelIndicator === 'A' ||
      vehicle?.value?.StockLevelIndicator === 'R'
    )
  })

  // TODO: Move to `useStockLevel`
  const isOutOfStock = computed(
    () => hasStockLevel.value && vehicle?.value?.StockLevelIndicator === 'R'
  )

  const routeCondition = computed(() => {
    return isNew.value ? ERouteConditions.New : ERouteConditions.Used
  })

  const vehicleRetailer = computed<IRetailer | undefined>(() => {
    if (vehicle?.value?.IsDisplayStock) return vehicle?.value.DisplayRetailer

    return vehicle?.value?.OwningRetailer || vehicle?.value?.Retailer
  })

  const isGalleryAvailable = computed(() => {
    if (vehicle?.value?.Media?.NoImage) return false

    return isNew.value
      ? vehicle?.value?.Media && vehicle?.value?.Media.MainImageUrl.length > 0
      : vehicle?.value?.Media &&
          vehicle?.value?.Media.VehicleImageUrls &&
          vehicle?.value?.Media.VehicleImageUrls.length > 0
  })

  return {
    isGalleryAvailable,
    canBuy,
    isNew,
    isMPV,
    isAMG,
    isSmart,
    EVehicleType,
    hasStockLevel,
    isOutOfStock,
    deliveryTime,
    routeCondition,
    isAdvertiseOnly,
    conditionSlug,
    vehicleRetailer,
    isNightMode,
    showP11d
  }
}

export const useVehicleFilters = () => {
  const filtersStore = useFiltersStore()

  const { active } = storeToRefs(filtersStore)

  const { updateModels, saveFilters } = useFilters(active.value)

  const addModelFilter = (vehicle: NewVehicle | UsedVehicle) => {
    const queryParams = filtersStore.getActiveQueryParams

    const queryModels = Array.isArray(queryParams.ModelId)
      ? queryParams.ModelId
      : (queryParams.ModelId &&
          (queryParams.ModelId as string).split(',').map(Number)) ||
        []

    const models = queryModels?.includes(vehicle.ModelId as number)
      ? (queryModels as number[])
      : ([...(queryModels || []), vehicle.ModelId] as number[])

    updateModels(models)
    saveFilters()
  }

  return { addModelFilter }
}
